import { Component, DoCheck, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { PathNames } from '../../app-routing-pathnames';


@Component({
    selector: 'app-cookie-popup',
    templateUrl: './cookie-popup.component.html',
    styleUrls: ['./cookie-popup.component.scss']
})
export class CookieComponent implements DoCheck, OnInit {
    showCookiePopup: boolean;
    environment = environment;
    isDetailVisible = false;

    constructor(
        private cookieService: CookieService
    ) { }

    ngOnInit() {
      this.ngDoCheck();
    }

    ngDoCheck() {
      if (window.location.pathname !== '/' + PathNames.cookieResetPage.url) {
        this.cookieService.check('cookiePopup') ? this.showCookiePopup = false : this.showCookiePopup = true;
      }
    }

    acceptCookie() {
      this.cookieService.set('cookiePopup', 'accept', 30, '/', null, false, 'Lax');
      this.showCookiePopup = false;
    }

    toggleShowMore() {
        this.isDetailVisible = !this.isDetailVisible;
    }
}
