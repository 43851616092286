import { CarSale } from '@coc-kfz-digital/oma-rest-api-client';
import { Component } from '@angular/core';
import * as moment from 'moment';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-label',
  templateUrl: './form-label.component.html',
  styleUrls: ['./form-label.component.scss']
})
export class FormLabelComponent extends AbstractFormFieldComponent {

  carSale: CarSale;

  constructor() {
    super();
  }

  getTranslationParams(labelKeyAndParam: string): object {
    const parameter = this.getSplittedTranslationComponents(labelKeyAndParam)[1];

    // special case: recommendation of sfKlasse
    if (parameter === 'calculateSFKlasse') {
      return this.calculateRecommendedSFKlasse();
    } else {
      if (parameter === 'telephone') {
        return {
          'telephone': this.environment.telephone_number,
        };
      } else {
        super.getTranslationParams(labelKeyAndParam);
      }
    }
  }

  calculateRecommendedSFKlasse(): object {
    let sfKlasse: string;

    this.carSale = this.elResolveTarget ? this.elResolveTarget.carsale : {};

    if (this.carSale && this.carSale.customerProfile) {

      const birthday = new Date(this.carSale.customerProfile.birthday);
      const age = moment().diff(birthday, 'year');

      // special treatment for young drivers
      // 18 --> 0 , 19 --> 1/2,  20 --> 1, 21 --> 1, 22 --> 2, 23 --> 3, etc..

      if (age <= 18) {
        sfKlasse = '0';
      } else if (age === 19) {
        sfKlasse = '1/2';
      } else if (age === 20) {
        sfKlasse = '1';
      } else {
        sfKlasse = Math.min(age - 20, 35).toString();
      }

      sfKlasse = 'SF-' + sfKlasse;
    }
    return {
      'sfKlasse': sfKlasse
    };
  }
}
