import { ApiModule } from '@coc-kfz-digital/oma-rest-api-client';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { BrowserModule } from '@angular/platform-browser';
import { TranslateModule } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie-service';
import { OrderModule } from 'ngx-order-pipe';
import { SharedModule } from '../shared/shared.module';
import { CookieComponent } from './cookie-popup/cookie-popup.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { NavigationMobileComponent } from './navigation-bar-mobile/navigation-bar-mobile.component';
import { NavigationComponent } from './navigation-bar/navigation-bar.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TariffBenefitListComponent } from './results-pages/benefit-list/tariff-benefit-list.component';
import { InsurerLogoComponent } from './results-pages/insurer-logo/insurer-logo.component';
import { NoResultTileComponent } from './results-pages/no-result-tile/no-result-tile.component';
import { TariffTileComponent } from './results-pages/tariff-tile/tariff-tile.component';
import { TileListComponent } from './results-pages/tile-list/tile-list.component';
import { SpinnerOverlayComponent } from './spinner-overlay/spinner-overlay.component';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
    TariffTileComponent,
    InsurerLogoComponent,
    TileListComponent,
    NoResultTileComponent,
    CookieComponent,
    SpinnerOverlayComponent,
    TariffBenefitListComponent,
    NavigationComponent,
    NavigationMobileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    BrowserModule,
    OrderModule,
    BrowserModule,
    HttpClientModule,
    ApiModule,
    MatCheckboxModule,
    ReactiveFormsModule,
    FormsModule,
    MatProgressBarModule
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SafeHtmlPipe,
    TileListComponent,
    TariffTileComponent,
    InsurerLogoComponent,
    NoResultTileComponent,
    CookieComponent,
    SpinnerOverlayComponent,
    TariffBenefitListComponent,
    NavigationComponent,
    NavigationMobileComponent,
  ],
  providers: [
    CookieService
  ]
})
export class OmaCommonModule { }
