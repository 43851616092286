import { ApiModule, BASE_PATH, Configuration, ConfigurationParameters } from '@coc-kfz-digital/oma-rest-api-client';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { Injector, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AngularIbanModule } from 'angular-iban';
import { Ng5SliderModule } from 'ng5-slider';
import { CookieService } from 'ngx-cookie-service';
import { OrderModule } from 'ngx-order-pipe';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { OmaCommonModule } from './common/common.module';
import { AuthInterceptor } from './common/interceptors/auth.interceptor';
import { DynamicFormModule } from './dynamic-form/dynamic-form.module';
import { AppInjectorService } from './shared/services/app-injector.service';
import { SharedModule } from './shared/shared.module';
import { AbstractResultPageComponent } from './views/abstract-result-page/abstract-result-page.component';
import { ComparativeResultsPageComponent } from './views/comparative-results-page/comparative-results-page.component';
import { CookieResetPageComponent } from './views/cookie-reset-page/cookie-reset-page.component';
import { DetailedCalculationPageComponent } from './views/detailed-calculation-page/detailed-calculation-page.component';
import { DetailedResultsPageComponent } from './views/detailed-results-page/detailed-results-page.component';
import { GeneralErrorPageComponent } from './views/general-error-page/general-error-page.component';
import { LoginPageComponent } from './views/login-page/login-page.component';
import { PaymentDetailsPageComponent } from './views/payment-details-page/payment-details-page.component';
import { PreviousInsurerPageComponent } from './views/previous-insurer-page/previous-insurer-page.component';
import { ProposalResultPageComponent } from './views/proposal-result-page/proposal-result-page.component';
import { QuickCalculationPageComponent } from './views/quick-calculation-page/quick-calculation-page.component';
import { SummaryPageComponent } from './views/summary-page/summary-page.component';
import { WelcomePageComponent } from './views/welcome-page/welcome-page.component';

@NgModule({
  declarations: [
    AppComponent,
    WelcomePageComponent,
    SummaryPageComponent,
    QuickCalculationPageComponent,
    ComparativeResultsPageComponent,
    DetailedCalculationPageComponent,
    LoginPageComponent,
    GeneralErrorPageComponent,
    DetailedResultsPageComponent,
    AbstractResultPageComponent,
    PreviousInsurerPageComponent,
    PaymentDetailsPageComponent,
    ProposalResultPageComponent,
    CookieResetPageComponent,
  ],
  imports: [
    // project specific modules
    AppRoutingModule,
    ApiModule.forRoot(ApiConfigFactory),
    OmaCommonModule,
    SharedModule,
    DynamicFormModule,

    // other modules
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatButtonToggleModule,
    MatInputModule,
    MatCheckboxModule,
    MatDividerModule,
    MatProgressBarModule,
    Ng5SliderModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    OrderModule,
    AngularIbanModule,
  ],
  providers: AppModuleProviderFactory(),
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private injector: Injector) {
    registerLocaleData(localeDe, 'de');
    AppInjectorService.setInjector(this.injector);
  }
}

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

/**
 * Configuration factory for REST Api.
 * At least empty configuration needs to be created here.
 */
export function ApiConfigFactory(): Configuration {
  const params: ConfigurationParameters = {
    // set default configuration parameters here
    withCredentials: true
  };

  return new Configuration(params);
}

export function AppModuleProviderFactory(): Provider[] {
  return [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    { provide: LOCALE_ID, useValue: 'de' },
    {
      provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
      useValue: {
        useUtc: true
      }
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    CookieService,
  ];
}
