export interface PathName {
  [key: string]: Path;
}

export interface Path {
  url: string;
  name: string;
  count: number;
  hideCookiePopup?: boolean;
}

export const PathNames: PathName = {
    empty: { url: '', name: '', count: null },
    loginPage: { url: 'login', name: 'Anmeldung', count: null },
    welcomePage: { url: 'willkommen', name: 'Willkommen', count: 0 },
    quickCalculationPage: { url: 'schnellberechnung', name: 'Schnellberechnung', count: 1 },
    comparativeResultsPage: { url: 'vergleich', name: 'Vergleich', count: 2 },
    detailedCalculationPage: { url: 'detailberechnung', name: 'Detailberechnung', count: 3 },
    detailedResultsPage: { url: 'tarifdetails', name: 'Tarifdetails', count: 4 },
    previousInsurerPage: { url: 'antrag', name: 'Antrag', count: 5 },
    paymentDetailsPage: { url: 'zahlungsinformationen', name: 'Zahlung', count: 6 },
    summaryPage: { url: 'zusammenfassung', name: 'Zusammenfassung', count: 7 },
    proposalResultPage: { url: 'abschluss', name: 'Abschluss' , count: null },
    cookieResetPage: { url: 'cookiezuruecksetzen', name: 'Cookielöschen', count: null, hideCookiePopup: true },
    errorPage: { url: 'fehler', name: 'Fehlerseite' , count: null, hideCookiePopup: true },
    pageNotFoundPage: { url: '**' , name: '', count: null }
};

export const PathParams = {
    carSaleId: 'carsaleId'
};
