/**
 * Interface for mapping insurance provider ids to image names
 */
export interface ImageMapping {
    [key: string]: string;
}

/**
 * Interface for mapping insurance provider ids to creditor ids
 */
export interface CreditorMapping {
    [key: string]: string;
}

/**
 * Mapping of insurance provider ids to image names
 */
export const INSURANCE_PROVIDER_IMAGE_MAPPING: ImageMapping = {
    5862: 'vhv.gif',                // vvh
    5798: 'rheinland.gif',          // rheinland
    5783: 'wuerttembergische.gif',  // wuerttembergische
    5058: 'kravagallgemeine.gif',   // kravagallgemeine
    5080: 'kravaglogistic.gif',     // kravaglogistic
    5426: 'nuernberger.png',        // nuernberger allgemeine
    5686: 'nuernberger.png',        // nuernberger beamten
    5505: 'nuernberger.png',        // nuernberger garanta
    5312: 'allianz.gif',            // allianz
    5055: 'verti.gif',              // directline / verti
    5316: 'bgv.gif',                // bgv
    5051: 'sdirekt.gif',            // sdirekt
    5562: 'ergo-direkt.gif',        // ergo-direkt
    5472: 'ergo.jpg',               // ergo
    5085: 'hdi.gif',                // hdi
    5515: 'axa.gif',                // axa
    5155: 'axa.gif',                // axa easy
    5448: 'nationale.gif',          // nationale
    5438: 'ruv.gif',                // ruv
    5151: 'zurich.gif',             // zurich
    5401: 'itzehoer.gif',           // itzehoer
    5384: 'helvetia.gif',           // helvetia
    5484: 'volkswohlbund.gif',      // volkswohlbund
    5446: 'provinzial.gif',         // provinzial nord
    5095: 'provinzial.gif',         // provinzial düsseldorf
};

/**
 * Mapping of insurance provider ids to creditor ids
 */
export const INSURANCE_PROVIDER_CREDITOR_MAPPING: CreditorMapping = {
    5862: 'DE32VHV00000150810', // vvh
    5798: 'DE22ZZZ00000086873', // rheinland
    5783: 'DE76WGV00000020232', // wuerttembergische.gif
    5058: 'DE3402500000136418', // kravagallgemeine
    5080: 'DE9102300000136556', // kravaglogistic
    5426: 'DE26ZZZ00000022102', // nuernberger allgemeine
    5686: 'DE03ZZZ00000057145', // nuernberger beamten
    5505: 'DE83ZZZ00000025053', // nuernberger garanta
    5312: 'DE10ZZZ00000051878', // allianz
    5055: 'DE34ZZZ00000111101', // directline / verti
    5316: 'DE76BAG00000122858', // bgv
    5051: 'DE04ZZZ00000080213', // sdirekt
    5562: 'DE25EDL00000041714', // ergo-direkt
    5472: 'DE05ZZZ00000012101', // ergo
    5085: 'DE31ZZZ00000051888', // hdi
    5515: 'DE23G0100000066097', // axa
    5155: 'DE23ZZZ00000066097', // axa easy
    5448: 'DE90ZZZ00000068771', // nationale
    5438: 'DE6300100000136090', // ruv
    5151: 'DE38ZZZ00000023244', // zurich
    5401: 'DE98ZZZ00000065426', // itzehoer
    5384: 'DE17ZZZ00000031285', // helvetia
    5484: 'DE83ZZZ00000141065', // volkswohlbund
    5446: 'DE55PNB00000107619', // provinzial nord
    5095: 'DE80ZZZ00000080203', // provinzial düsseldorf
};


