import {Component, OnDestroy, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../common/services/auth.service';
import { AppInjectorService } from '../../shared/services/app-injector.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cookie-reset-page',
  templateUrl: './cookie-reset-page.component.html',
  styleUrls: ['./cookie-reset-page.component.scss']
})
export class CookieResetPageComponent implements OnInit, OnDestroy {

  environment = environment;

  protected showPopup = false;
  protected authService: AuthService;

  private logoutSubscription: Subscription;

  constructor( private cookieService: CookieService) {
    const injector = AppInjectorService.getInjector();
    this.authService = injector.get(AuthService);
  }

  ngOnInit() {
    this.resetCookies();
  }

  ngOnDestroy() {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  public resetCookies() {
    this.cookieService.deleteAll('/');
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    this.logoutSubscription = this.authService.deleteLoginToken().subscribe();
    this.showPopup = true;
  }
}
