import { ProposalResponse } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { AbstractPageComponent } from '../abstract-page/abstract-page.component';

export enum ProposalStatus {
  Loading = 'Loading',
  Success = 'Success',
  Fail = 'Fail'
}

@Component({
  selector: 'app-proposal-result-page',
  templateUrl: './proposal-result-page.component.html',
  styleUrls: ['./proposal-result-page.component.scss']
})
export class ProposalResultPageComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  private proposalSubscription: Subscription;
  private proposalResponse: ProposalResponse = {};

  proposalStatus = ProposalStatus.Loading;

  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscribeToProposal();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    safeUnsubscribe(this.proposalSubscription);
  }

  private subscribeToProposal() {
    const proposalObservable = this.carInsuranceProposalService.executeProposal();
    this.proposalSubscription = proposalObservable.subscribe(
      {
        next: (data: ProposalResponse) => this.refreshProposalData(data),
        error: () => this.proposalStatus = ProposalStatus.Fail,
      }
    );
  }

  private refreshProposalData(response: ProposalResponse) {
    this.proposalResponse = response;

    if (this.isSuccessfulResponse(response)) {
      this.proposalStatus = ProposalStatus.Success;
      this.authService.updateApplicationState(response.applicationState);
      this.carSaleCacheService.clear();
    } else {
      this.proposalStatus = ProposalStatus.Fail;
    }
  }

  /**
   * Returns if the given response is successful by checking the errorCode in the response.
   * (errorCode === 0) => success
   *
   * @param response the response to check
   */
  private isSuccessfulResponse(response: ProposalResponse): boolean {
    return (response &&
      response.headerError &&
      response.headerError.errorCode === 0);
  }
}
