import { FormField } from '@coc-kfz-digital/oma-rest-api-client';
import { Component } from '@angular/core';
import { MatButtonToggle, MatButtonToggleChange } from '@angular/material/button-toggle';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { AbstractFormFieldComponent } from '../abstract-form-field/abstract-form-field.component';

@Component({
  selector: 'app-form-quick-toggle-datepicker',
  templateUrl: './form-quick-toggle-datepicker.component.html',
  styleUrls: ['./form-quick-toggle-datepicker.component.scss']
})
export class FormQuickToggleDatepickerComponent extends AbstractFormFieldComponent {

  constructor() {
    super();
  }

  /**
   * Static method to retrieve the control name for the virtual toogle control of the FormQuickToggleDatepickerComponent.
   *
   * @param fieldConfig the field specification the control name is generated for
   */
  static getToggleControlNameForFieldConfig(fieldConfig: FormField): string {
    return this.getToggleControlNameForFieldName(fieldConfig.name);
  }

  /**
   * Static method to retrieve the control name of the virtual toogle button of the component with given field name
   */
  static getToggleControlNameForFieldName(fieldName: string): string {
    return 'quicktoggledatepicker_toggle_for_' + fieldName;
  }

  /**
   * Returns the control name of the virtual toogle button of this component instance
   */
  getToggleControlName(): string {
    return FormQuickToggleDatepickerComponent.getToggleControlNameForFieldConfig(this.fieldConfig);
  }

  /**
   * Change listener for the toogle button. Will open the datepicker popup
   *
   * @param changeEvent the change event
   * @param datepickerReference  a reference to datepicker object in the template
   */
  onToggleGroupChange(changeEvent: MatButtonToggleChange, datepickerReference: MatDatepicker<moment.Moment>) {
    if (changeEvent.value === '#select_date') {
      datepickerReference.open();
    } else if (changeEvent.value === '#today') {

      // set today's date
      this.control().setValue(this.parseDate('#today'));
    }
  }

  onToggleButtonClick(togglebuttonReference: MatButtonToggle, datepickerReference: MatDatepicker<moment.Moment>) {
    if (togglebuttonReference.value !== '#today') {
      datepickerReference.open();
    }
  }

  minDate(): moment.Moment {
    const rule = this.getDateValidationRule();

    if (!rule || rule === 'undefined' || !rule.parameters) {
      return null;
    }

    return this.parseDate(rule.parameters.minDate);
  }

  maxDate(): moment.Moment {
    const rule = this.getDateValidationRule();

    if (!rule || rule === 'undefined' || !rule.parameters) {
      return null;
    }

    return this.parseDate(rule.parameters.maxDate);
  }

  startDate() {
    const rule = this.fieldConfig.placeholder;

    if (this.control().value) {
      return this.control().value;
    }

    if (rule === '#birthdate_plus_18_years') {
      return moment(this.elResolveTarget.carsale.customerProfile.birthday).add(18, 'years').startOf('day');
    } else {
      return null;
    }
  }

  private getDateValidationRule() {
    return this.fieldConfig.validationRules.find(validationRule =>
      validationRule.name === 'date');
  }

  public parseDate(date: string): moment.Moment {

    switch (date) {
      // ignore
      case null: {
        return null;
      }

      // special case #today
      case '#today': {
        return moment();
      }

      // special case #yesterday
      case '#yesterday': {
        return moment().subtract(1, 'days');
      }

      // special case #17_years_ago
      case '#17_years_ago': {
        const d = moment().subtract(17, 'years').startOf('day');
        return d;
      }

      // special case #18_years_ago
      case '#18_years_ago': {
        const d = moment().subtract(18, 'years').startOf('day');
        return d;
      }

      // special case #last100years
      case '#100_years_ago': {
        const d = moment().subtract(100, 'years');
        return d;
      }

      // special case #last140years
      case '#140_years_ago': {
        const d = moment().subtract(140, 'years');
        return d;
      }

      // special case 17_years_minus_8_weeks_ago
      case '#17_years_minus_8_weeks_ago': {
        const d = moment().subtract(17, 'years').add(8, 'weeks');
        return d;
      }

      // parse date from given minDate
      default: {
        return moment(date);
      }
    }
  }
}
