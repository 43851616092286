import { FormField, FormFieldDependency, FormFieldType, FormResponseUiModel, FormValue, LookupService, ValidationRule } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { ValidatorService } from 'angular-iban';
import { SuperForm } from 'angular-super-validator';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { FormQuickToggleDatepickerComponent } from '../../components/form-quick-toggle-datepicker/form-quick-toggle-datepicker.component';
import { CustomValidators } from '../../validators/custom-validators';

/**
 * Interface for all dynamic form parameter
 */
export interface DynamicFormContent {
  uiModel: FormResponseUiModel;
  elResolveTarget: object;
  restoreState: FormValues;
}

/**
 * Interface for store of a form state
 */
export interface FormValues {
  [key: string]: any;
}

/**
 * Interface for store of a form state
 */
export interface AfterFormInitializationHookEvent {
  form: FormGroup;
  uiModel: FormResponseUiModel;
  restoreState: FormValues;
  elResolveTarget: any;
}

/**
 * Interface for storage of form values provided by a lookup service
 */
export interface LookupFormValues {
  [key: string]: Array<FormValue>;
}

/**
 * Interface for submit status
 */
export interface FormStatus {
  submitted: boolean;
  initialized?: boolean;
}

/**
 * Interface for storage of form value change subscriptions
 */
interface ValueChangeSubscription {
  [key: string]: Subscription;
}

/**
 * Interface for storage of form status change subscriptions
 */
interface StatusChangeSubscription {
  [key: string]: Subscription;
}

/**
 * Interface for storage of value lookup subscriptions
 */
interface ValueLookupSubscription {
  [key: string]: Subscription;
}

/**
 * Combined type definition for containers holding subscriptions
 */
type SubscriptionContainer = ValueChangeSubscription | StatusChangeSubscription | ValueLookupSubscription;

/**
 * Interface for easy access to form field definitions.
 * Map: fieldName -> FormField definition
 */
interface FormFieldMap {
  [key: string]: FormField;
}

@Component({
  selector: 'app-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {

  // the ui model describing the dynamic form
  @Input() uiModel: FormResponseUiModel;

  // the former state of the dynamic form to be restored
  @Input() restoreState: FormValues = {};

  // the object to be used to resolve EL (expression language terms) into values
  @Input() elResolveTarget: any;

  @Input() status: FormStatus = {
    submitted: false
  };

  // event emitter for form submission
  @Output() submitted: EventEmitter<FormValues> = new EventEmitter();

  // event emitter for after form initialization hooks
  @Output() afterFormInitializationHook: EventEmitter<AfterFormInitializationHookEvent> = new EventEmitter();

  // the form group representing the state of the dynamic form
  form: FormGroup;

  // a map with all form fields
  formFields: FormFieldMap = {};

  // change subscriptions
  valueChangeSubscriptions: ValueChangeSubscription = {};
  statusChangeSubscriptions: StatusChangeSubscription = {};

  // value lookup subscriptions
  valueLookupSubscriptions: ValueLookupSubscription = {};

  // a map with all values from value lookups
  lookupFormValues: LookupFormValues = {};

  environment = environment;

  constructor(private fb: FormBuilder, private lookupService: LookupService) { }

  ngOnInit() {
    this.initializeFormGroup();
  }

  ngOnChanges(changes: SimpleChanges): void {

    // form specification changed => cleanup & reinitialize dynamic form
    const uiModelChanged = changes.uiModel ? (changes.uiModel.currentValue !== changes.uiModel.previousValue) : false;
    const restoreStateChanged = changes.restoreState ? (changes.restoreState.currentValue !== changes.restoreState.previousValue) : false;
    const elResolveTargetChanged = changes.elResolveTarget ?
      (changes.elResolveTarget.currentValue !== changes.elResolveTarget.previousValue) : false;

    if (uiModelChanged || restoreStateChanged || elResolveTargetChanged) {
      this.releaseFormFieldDependencies();
      this.initializeFormGroup();
      this.sectionVisibilityCheck();
    }
  }

  ngOnDestroy(): void {
    this.releaseFormFieldDependencies();
  }

  /**
   * Initializes the form group and add all controls that are given by the UI model
   */
  private initializeFormGroup() {
    this.status.initialized = false;

    // initialize form group
    this.form = this.fb.group({});

    if (this.isValidDynamicForm()) {
      this.initializeFormFields();
      this.initializeFormFieldDependencies();
      this.initializeGlobalValidators();
      this.afterFormInitializationHook.emit({
        form: this.form,
        uiModel: this.uiModel,
        restoreState: this.restoreState,
        elResolveTarget: this.elResolveTarget
      });
      this.status.initialized = true;
    }
  }

  /**
   * Returns whether a current dynamic form is valid
   */
  isValidDynamicForm(): boolean {
    return (
      this.uiModel &&
      this.restoreState &&
      this.elResolveTarget);
  }

  /**
   * Initalize form fields
   */
  private initializeFormFields() {

    if (!this.isValidDynamicForm()) {
      return;
    }

    // reset form field map
    this.formFields = {};

    // create controls for all fields in sections and containers and bind them to the form group
    for (const section of this.uiModel.sections) {
      for (const container of section.containers) {
        for (const field of container.fields) {

          // initialize form field map
          this.formFields[field.name] = field;

          // retrieve field validators
          const validators = this.getFieldValidators(field);

          // retrieve initial field value
          const initialValue = this.determineFormFieldState(field.name, field.initialValue, field.type.fieldType);

          // create control element for specified field using initial value and validators
          const control = this.fb.control(initialValue, validators);

          // bind control to formgroup using the field name from specification
          this.form.addControl(field.name, control);

          // bind additional virtual controls to formgroup if necessary
          this.addVirtualControlsForField(field);
        }
      }
    }
  }

  /**
   *  Determines the initial value for the field by precedence:
   *
   * 1. if existent use old state from restore state
   * 2. if no old state exists, use initial value given by field specification
   * 3. else use empty inital value
   *
   * @param fieldName the name of the field
   * @param fieldInitialValue the initial value from form specification
   */
  private determineFormFieldState(fieldName: string, fieldInitialValue: string, fieldType: FormFieldType.FieldTypeEnum): any {
    const restoreState = this.restoreState ? this.restoreState[fieldName] : undefined;
    let initialValue = restoreState ? restoreState : this.parseEL(fieldInitialValue);
    initialValue = (initialValue === undefined || initialValue === null) ? '' : initialValue;

    // special handling for boolean types
    if (fieldType === 'boolean') {

      // parse boolean value from string
      initialValue = (initialValue === 'true');
    }

    return initialValue;
  }

  /**
   * Parses the given value for possible expressions and resolves the expressions against the EL target
   *
   * @param value the value to parse
   */
  private parseEL(value: string) {
    if (value !== undefined && value != null) {

      // regex to find all occurences of variable expressions e.g.: ${object.path.variable}
      const regexp = /\$\{{1}([\w\.]+)\}{1}/g;

      // replace expression statement with value of that expression
      return value.replace(regexp, (match, expression) => this.evaluateExpression(match, expression));
    } else {
      return value;
    }
  }

  /**
   * Evaluates the given expression agains the EL target
   *
   * @param match the orignal match from regular expression
   * @param expression the expression to evaluate (e.g. carsale.car.vin)
   *
   * ```
   * Example: The expression 'carsale.car.vin' would evaluate to 'ABC' for the below example EL target:
   * // elTarget =
   * // {
   * //   carsale: {
   * //     car: {
   * //         vin: 'ABC'
   * //       }
   * //   }
   * // }
   * ```
   */
  private evaluateExpression(match: string, expression: string): string {
    /**
     * @see https://lodash.com/docs/4.17.15#hasIn
     * @see https://lodash.com/docs/4.17.15#get
     */
    if (_.hasIn(this.elResolveTarget, expression)) {
      const resolved = _.get(this.elResolveTarget, expression);
      return (resolved === null || resolved === undefined) ? '' : resolved;
    } else {
      console.error('Could not parse and/or resolve given expression [' + match + ']');
      return match;
    }
  }

  /**
   * Binds additional virtual controls to formgroup if necessary
   *
   * @param field the form field
   */
  private addVirtualControlsForField(field: FormField) {

    // expansion point for different component types
    if (field.type.uiType === 'quicktoggledatepicker') {
      this.addVirtualFieldForQuickToogleDatepicker(field);
    }

  }

  /**
   * Binds an additional virtual control for QuickToogleDatepicker component given by field
   * @see FormQuickToggleDatepickerComponent
   *
   * @param field the form field
   */
  private addVirtualFieldForQuickToogleDatepicker(field: FormField) {
    if (field.type.uiType !== 'quicktoggledatepicker') {
      return;
    }

    // retrieve control name
    const virtualControlName = FormQuickToggleDatepickerComponent.getToggleControlNameForFieldConfig(field);

    // retrieve initial field value
    const initialValue = this.determineFormFieldState(virtualControlName, null, field.type.fieldType);

    // create control element for virtual field with initial value
    const control = this.fb.control(initialValue);

    // bind control to formgroup using the virtual control name
    this.form.addControl(virtualControlName, control);
  }

  /**
   * Initializes all dependencies between form fields
   */
  private initializeFormFieldDependencies() {

    if (!this.isValidDynamicForm()) {
      return;
    }

    for (const section of this.uiModel.sections) {
      for (const container of section.containers) {
        for (const field of container.fields) {

          // for each field with at least one dependency => register value change listener
          if (field.dependencies && field.dependencies.length >= 1) {
            this.registerChangeListeners(field);
          }
        }
      }
    }

    // trigger a one time value change to initialize visibility / values of dependent fields
    this.initializeDependentFields();
  }

  /**
   * Releases all dependencies between form fields
   */
  private releaseFormFieldDependencies() {

    // unsubscribe all value change subscriptions
    // unsubscribe all status change subscriptions
    // unsubscribe all value lookup subscriptions
    const subscriptionContainers = [this.valueChangeSubscriptions, this.statusChangeSubscriptions, this.valueLookupSubscriptions];
    this.unsubscribeAll(subscriptionContainers);
  }

  /**
   * Unsubscribes all subscriptions stored in the given subscription containers
   *
   * @param containers the array of subscription containers
   */
  private unsubscribeAll(containers: SubscriptionContainer[]) {

    containers.forEach(container => {
      // iterate over all keys in container and unsubscribe the stored subscriptions
      Object.keys(container).forEach(key => {
        safeUnsubscribe(container[key]);
      });
    });
  }

  /**
   * Initializes all dependent fields
   */
  private initializeDependentFields() {

    for (const section of this.uiModel.sections) {
      for (const container of section.containers) {
        for (const field of container.fields) {
          if (field.dependencies && field.dependencies.length >= 1) {
            // set initial state (disabled/enabled) of all dependent fields
            this.evaluateValueChange(field, this.form.controls[field.name].value);
            this.evaluateStatusChange(field, this.form.controls[field.name].status);
          }
        }
      }
    }
  }

  /**
   * Creates value and status change listener for the given form field
   *
   * @param formField the form field
   */
  private registerChangeListeners(formField: FormField) {
    const fieldName = formField.name;

    // create subscription for value changes of the given form field
    const valueSubscription = this.form.controls[fieldName].valueChanges.subscribe(value =>
      this.evaluateValueChange(formField, value));

    // create subscription for status changes of the given form field
    const statusSubscription = this.form.controls[fieldName].statusChanges.subscribe(status =>
      this.evaluateStatusChange(formField, status));

    // store subscription for later removal
    this.valueChangeSubscriptions[fieldName] = valueSubscription;
    this.statusChangeSubscriptions[fieldName] = statusSubscription;
  }

  /**
   * Evaluator function for value changes of the given field
   *
   * @param formField the form field
   * @param value the changed value
   */
  private evaluateValueChange(formField: FormField, value: string) {

    for (const fieldDependency of formField.dependencies) {

      const triggerMatching = this.isTriggerMatching(formField, fieldDependency, value);

      if (fieldDependency.type === 'valueLookup') {
        this.executeValueLookup(fieldDependency, value, triggerMatching);
      } else {
        this.executeVisibilityChange(fieldDependency, triggerMatching);
      }
    }
  }

  /**
   * Evaluator function for status changes of the given field
   *
   * @param formField the form field
   * @param status the changed status
   */
  private evaluateStatusChange(formField: FormField, status: string) {

    for (const fieldDependency of formField.dependencies) {

      // if the field gets disabled => disable all dependent fields
      if (status === 'DISABLED') {
        this.executeVisibilityChange(fieldDependency, false);
      }
    }
  }

  /**
   * Checks whether the given value is matching the trigger values of the given dependency
   *
   * @param formField the form field
   * @param dependency the dependency that defines the trigger values
   * @param value the value to check
   */
  private isTriggerMatching(formField: FormField, dependency: FormFieldDependency, value: string) {

    if (dependency.triggerValues.includes('*')) {
      // wildcard trigger => always matching
      return true;
    } else if (dependency.triggerValues.includes('#valid') &&
      this.form.controls[formField.name].valid) {
      // valid trigger => match if field is valid
      return true;
    } else if (dependency.triggerValues.includes('#invalid') &&
      this.form.controls[formField.name].invalid) {
      // invalid trigger => match if field is invalid
      return false;
    } else {
      // default trigger => match if field value is a trigger value

      // convert value explicitly to string to allow string comparison on boolean or other types as well
      const strictStringValue = value.toString();
      return dependency.triggerValues.includes(strictStringValue);
    }
  }

  /**
   * Executes a visibility change of the form field defined by the given dependency
   *
   * @param dependency the dependency that defines where the action needs to be triggered
   * @param matchingTrigger indicator if the trigger of the given dependency is currently matching or not
   */
  private executeVisibilityChange(dependency: FormFieldDependency, matchingTrigger: boolean) {
    const dependentControl = this.form.controls[dependency.name];

    if (!dependentControl) {
      console.error('Could not find control with name [' + dependency.name + '] used as dependency target. '
        + 'Please check the ui model for consistency. As the field is unknown the action is ignored.');
      return;
    }

    if (matchingTrigger) {
      dependentControl.enable();
    } else {
      dependentControl.disable();
    }
  }

  sectionVisibilityCheck(): void {
    if (this.uiModel !== null && this.uiModel !== undefined) {
      for (const section of this.uiModel.sections) {
        if (section.dependencies !== null && section.dependencies !== undefined) {
          let dependencyCount = 0;
          for (const dependency of section.dependencies) {
            if (this.form.controls[dependency].disabled) {
              dependencyCount++;
            }
          }
          if (dependencyCount >= section.dependencies.length) {
            section.hidden = true;
          } else {
            section.hidden = false;
          }
        }
      }
    }
  }

  /**
   * Executes a value lookup for the given form field dependency and the given value
   *
   * @param dependency the dependency that defines the value lookup
   * @param value the value to enter to the lookup service
   */
  private executeValueLookup(dependency: FormFieldDependency, value: string, matchingTrigger: boolean) {

    if (!matchingTrigger) {
      return;
    }

    const lookupService = this.getLookupServiceForValue(dependency, value);

    if (!lookupService) {
      console.error('No lookup service found for dependency ' + JSON.stringify(dependency) + ' .');
      return;
    }

    // cancel subscription of previous call
    safeUnsubscribe(this.valueLookupSubscriptions[dependency.name]);

    // subscribe to lookup service for current value
    const valueLookupSubscription = lookupService.subscribe(
      lookupResponse => {
        this.useLookupResponse(dependency, lookupResponse);
        safeUnsubscribe(valueLookupSubscription);
      }
    );

    // store subscription for later removal
    this.valueLookupSubscriptions[dependency.name] = valueLookupSubscription;
  }

  /**
   * Returns a lookup service for the given dependency and current field value
   *
   * @param dependency the dependency specification
   * @param value the current field value
   */
  private getLookupServiceForValue(dependency: FormFieldDependency, value: string): Observable<any> {
    if (dependency.lookupService === 'registrationArea') {
      return this.lookupService.getRegistrationAreasForPostalCode(Number(value));
    } else {
      return null;
    }
  }

  /**
   * Use the lookup response to update the receiving field's values and select the first possible option as default
   *
   * @param dependency the dependency specification
   * @param lookupResponse the response that was returned by the lookup service call
   */
  private useLookupResponse(dependency: FormFieldDependency, lookupResponse: any[]) {

    // convert to form values
    const formValues = lookupResponse.map(responseEntry =>
      this.mapLookupResponseToFormValue(dependency, responseEntry));

    // update the value map
    this.lookupFormValues[dependency.name] = formValues;

    this.selectLookupValue(dependency, formValues);
  }

  /**
   * Maps a lookup response entry to a form value object
   *
   * @param dependency the dependency specification
   * @param lookupResponseEntry the response entry to map to a form value
   */
  private mapLookupResponseToFormValue(dependency: FormFieldDependency, lookupResponseEntry: any): FormValue {

    // load response entry mapping receiving field's values definition
    const responseEntryMapping = this.formFields[dependency.name].values[0];

    // map the response entry
    return {
      id: lookupResponseEntry[responseEntryMapping.id],
      value: lookupResponseEntry[responseEntryMapping.value],
      label: lookupResponseEntry[responseEntryMapping.label],
      iconPath: lookupResponseEntry[responseEntryMapping.iconPath]
    };
  }

  /**
   * Sets value of the reveiving field to a reasonable value contained in the lookup values by following precedence:
   *
   * 1. If multiple options are available, choose the previously selected value if still allowed
   * 2. If no initial selection found, choose first allowed value if not empty
   * 3. Otherwise reset selection
   *
   * @param dependency the dependency specification
   * @param formValues the array of form values that resulted from the lookup call
   */
  private selectLookupValue(dependency: FormFieldDependency, formValues: FormValue[]) {
    const dependentControl = this.form.controls[dependency.name];
    const restoreValue = this.restoreState[dependency.name];

    const initiallySelected = formValues.find(value => value.value === restoreValue);

    if (initiallySelected) {
      dependentControl.setValue(initiallySelected.value);
    } else {
      const firstValue = formValues[0] ? formValues[0].value : '';
      dependentControl.setValue(firstValue);
      dependentControl.markAsTouched();
    }
  }

  /**
   * Returns all errors of a form
   */
  getFormErrors() {
    return SuperForm.getAllErrors(this.form);
  }

  /**
   * Initalize global validators
   */
  private initializeGlobalValidators() {

    if (!this.isValidDynamicForm()) {
      return;
    }

    this.form.setValidators(this.getGlobalValidators());
  }

  /**
   * Returns an array of global form validator functions
   *
   */
  private getGlobalValidators(): ValidatorFn[] {
    const validatorFns = [];

    if (!this.isValidDynamicForm()) {
      return validatorFns;
    }

    // check if global validation rules are existent in specification
    if (this.uiModel.validationRules === null || this.uiModel.validationRules === undefined) {
      return validatorFns;
    }

    // instanciate validators based on specification
    for (const validationRule of this.uiModel.validationRules) {

      // create fake form field for global ui model
      const fakeGlobalFormField: FormField = { name: 'global' };

      const validator = this.loadValidator(fakeGlobalFormField, validationRule);

      if (validator) {
        validatorFns.push(validator);
      }
    }

    return validatorFns;
  }

  /**
   * Returns an array of validator functions for the given field
   *
   * @param formField the form field
   */
  private getFieldValidators(formField: FormField): ValidatorFn[] {
    const validatorFns = [];

    // check if field validation rules are existent in specification
    if (formField.validationRules === null || formField.validationRules === undefined) {
      return validatorFns;
    }

    // instanciate validators based on specification
    for (const validationRule of formField.validationRules) {

      const validator = this.loadValidator(formField, validationRule);

      if (validator) {
        validatorFns.push(validator);
      }
    }

    return validatorFns;
  }

  /**
   * Loads the validator function specified by the given validation rule
   *
   * @param formField the formfield
   * @param validationRule the validation rule
   */
  private loadValidator(formField: FormField, validationRule: ValidationRule): ValidatorFn {

    if (!validationRule.name) {
      console.error(
        'Missing validator rule name for form field [' + formField.name + '].'
      );
      return;
    }

    switch (validationRule.name) {

      // Required validator
      case ValidationRule.NameEnum.Required: {
        return Validators.required;
      }

      // RequiredTrue validator
      case ValidationRule.NameEnum.RequiredTrue: {
        return Validators.requiredTrue;
      }

      // Pattern validator
      case ValidationRule.NameEnum.Pattern: {
        if (!validationRule.parameters.regex) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'regex');
          return;
        }
        return Validators.pattern(validationRule.parameters.regex);
      }

      // DateLessThan validator
      case ValidationRule.NameEnum.DateLessThan: {
        if (!validationRule.parameters.dateLessThanFromFieldName) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'dateLessThanFromFieldName');
          return;
        }
        if (!validationRule.parameters.dateLessThanToFieldName) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'dateLessThanToFieldName');
          return;
        }

        if (validationRule.parameters.dateLessThanFromFieldName !== formField.name &&
          validationRule.parameters.dateLessThanToFieldName !== formField.name) {
          console.error(
            'Error during instanciation of validator rule for form field [' + formField.name + ']. ' +
            'Neither of the given parameters are matching the field name.');
        }

        return CustomValidators.dateLessThan(validationRule.parameters.dateLessThanFromFieldName,
          validationRule.parameters.dateLessThanToFieldName);
      }

      // Date validator
      case ValidationRule.NameEnum.Date: {
        // NOOP for now - to be replaced with real date validator
        return;
      }

      // MaxLength validator
      case ValidationRule.NameEnum.MaxLength: {
        if (!validationRule.parameters.maxLength) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'maxLength');
          return;
        }
        return Validators.maxLength(validationRule.parameters.maxLength);
      }

      // MinLength validator
      case ValidationRule.NameEnum.MinLength: {
        if (!validationRule.parameters.minLength) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'minLength');
          return;
        }
        return Validators.minLength(validationRule.parameters.minLength);
      }

      // min validator
      case ValidationRule.NameEnum.Min: {
        if (!validationRule.parameters.min) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'min');
          return;
        }
        return Validators.min(validationRule.parameters.min);
      }

      // max validator
      case ValidationRule.NameEnum.Max: {
        if (!validationRule.parameters.max) {
          this.printMissingValidatorParameterMessage(formField.name, validationRule.name, 'max');
          return;
        }
        return Validators.max(validationRule.parameters.max);
      }

      // email validator
      case ValidationRule.NameEnum.Email: {
        return Validators.email;
      }

      // iban validator
      case ValidationRule.NameEnum.Iban: {
        return ValidatorService.validateIban;
      }

      // default: print error message
      default: {
        console.error(
          'Given validator rule with name [' + validationRule.name + '] is unknown. ' +
          'Validator could not be registered. Have you forgot to add the name to the validator mapping in dynamic-form.component.ts?');
        return;
      }
    }
  }

  /**
   * Helper method to print an error log about missing required parameters of a validator
   *
   * @param formFieldName the name of the corresponding field
   * @param validatorName the name of the validator
   * @param parameterName the name of the missing parameter
   */
  private printMissingValidatorParameterMessage(formFieldName: string, validatorName: string, parameterName: string) {
    console.error(
      'Error during instanciation of validator rule for form field [' + formFieldName + ']. ' +
      'Missing required parameter [' + parameterName + '] for validator name [' + validatorName + ']'
    );
  }
}
