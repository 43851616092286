import { Car, CarInsuranceCalculationService, CarInsuranceProposalService, CarSale, Profile } from '@coc-kfz-digital/oma-rest-api-client';
import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/common/services/auth.service';
import { CarSaleCacheService } from 'src/app/common/services/carSaleCache.service';
import { AppInjectorService } from 'src/app/shared/services/app-injector.service';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';

@Injectable()
export abstract class AbstractPageComponent implements OnInit, OnDestroy {

  carSaleSubscription: Subscription;
  carSale: CarSale;
  car: Car;
  userProfile: Profile;

  environment = environment;

  protected injector: Injector;

  protected router: Router;
  protected authService: AuthService;
  protected formBuilder: FormBuilder;
  protected carSaleCacheService: CarSaleCacheService;

  protected carInsuranceProposalService: CarInsuranceProposalService;
  protected carInsuranceCalculationService: CarInsuranceCalculationService;

  constructor(
  ) {
    // Manually retrieve the dependencies from the injector
    // so that constructor has no dependencies that must be passed in from child
    this.injector = AppInjectorService.getInjector();

    this.router = this.injector.get(Router);
    this.authService = this.injector.get(AuthService);
    this.formBuilder = this.injector.get(FormBuilder);
    this.carSaleCacheService = this.injector.get(CarSaleCacheService);
    this.carInsuranceProposalService = this.injector.get(CarInsuranceProposalService);
    this.carInsuranceCalculationService = this.injector.get(CarInsuranceCalculationService);
  }

  ngOnInit() {
    if (this.authService.isAuthenticated(this.currentCarSaleId())) {
      this.subscribeToCarSaleData();
    }
  }

  ngOnDestroy(): void {
    safeUnsubscribe(this.carSaleSubscription);
  }

  subscribeToCarSaleData() {
    const carSaleObservable = this.carSaleCacheService.getCarSale();
    this.carSaleSubscription = carSaleObservable.subscribe(carSale => this.refreshCarSaleData(carSale));
  }

  private refreshCarSaleData(carSale: CarSale) {
    this.carSale = carSale;
    this.car = carSale.car;
    this.userProfile = carSale.customerProfile;
    this.authService.updateApplicationState(carSale.applicationState);
  }

  /**
   * Returns the the id of the current carsale
   */
  protected currentCarSaleId() {
    return this.authService.currentCarSaleId();
  }
}
