import { CarSaleService, Consent, InlineResponse200 } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { PathNames } from 'src/app/app-routing-pathnames';
import { toRestoreStateObservable } from 'src/app/common/request-parameter-utils';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { FormValues } from '../../dynamic-form/containers/dynamic-form/dynamic-form.component';
import { AbstractResultPageComponent } from '../abstract-result-page/abstract-result-page.component';

@Component({
  selector: 'app-summary-page',
  templateUrl: './summary-page.component.html',
  styleUrls: ['./summary-page.component.scss']
})
export class SummaryPageComponent extends AbstractResultPageComponent implements OnInit, OnDestroy {

  submitted = false;

  restoreState: FormValues;
  consentSubscription: Subscription;
  restoreStateSubscription: Subscription;
  summaryForm: FormGroup;
  environment = environment;

  constructor(private carSaleService: CarSaleService) {
    super();
    this.filterBySelectedTariff = true;
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.buildForm();

    this.restoreStateSubscription = this.getJoinedRestoreStateObservable().subscribe(data => {
      this.restoreState = this.concatStates(data.calcRestoreState, data.propRestoreState);
    });
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    // cleanup subscriptions
    safeUnsubscribe(this.restoreStateSubscription);
  }

  /**
   * method for retrieving the calculation and proposal page restore state observables
   * using forkJoin operator.
   */
  private getJoinedRestoreStateObservable() {
    return forkJoin({
      calcRestoreState: toRestoreStateObservable(this.carInsuranceCalculationService.getCalculation()),
      propRestoreState: toRestoreStateObservable(this.carInsuranceProposalService.getProposal())
    });
  }
  /**
   * method for concatenating two restore states into a single restoreState.
   * in the template we can then refer to only one 'restoreState' object.
   */
  private concatStates(stateA: FormValues, stateB: FormValues) {
    const restoreState: FormValues = {};

    Object.keys(stateA).forEach(key => {
      restoreState[key] = stateA[key];
    });

    Object.keys(stateB).forEach(key => {
      restoreState[key] = stateB[key];
    });

    return restoreState;
  }

  private buildForm() {
    this.summaryForm = this.formBuilder.group({
      consignment: ['', [Validators.requiredTrue]],
      consultingWaiver: ['', [Validators.requiredTrue]]
    });
  }

  /**
   * Event handler method that is invoked by submitting the page form
   * @param formValues the state of the form during submission
   */
  onSubmit() {
    this.submitted = true;

    this.consentSubscription = this.getJoinedConsentObservable().subscribe({
      next: response => this.handleConsentResponse(response),
      error: () => this.submitted = false,
    });
  }

  private handleConsentResponse(response: InlineResponse200) {
    safeUnsubscribe(this.consentSubscription);
    if (response.success === true) {
      this.router.navigate([PathNames.proposalResultPage.url, this.currentCarSaleId()]);
    }
  }

  /**
   * creating an observable for each consent object and joining them via forkJoin.
   * the resulting observable response gets transformed via the switchMap operator
   * in order to fit as a parameter into the handleConsentResponse method.
   */
  private getJoinedConsentObservable() {

    const consentContractDetails: Consent = {
      name: Consent.NameEnum.ContractDetails,
      version: environment.consent_versions.contract_details,
      accept: true,
    };

    const consentConsultingWaiver: Consent = {
      name: Consent.NameEnum.ConsultingWaiver,
      version: environment.consent_versions.consulting_waiver,
      accept: true,
    };

    return forkJoin({
      consignmentConsent: this.carSaleService.createCustomerConsent(consentContractDetails),
      consultingWaiverConsent: this.carSaleService.createCustomerConsent(consentConsultingWaiver)
    }).pipe(switchMap(response => {
      const success = response.consignmentConsent.success === true && response.consultingWaiverConsent.success === true;
      const combinedConsent: InlineResponse200 = { success };
      return of(combinedConsent);
    }));
  }

  editSection(section: string) {
    switch (section) {
      case 'quickCalculation': {
        this.router.navigate([PathNames.quickCalculationPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'detailCalculation': {
        this.router.navigate([PathNames.detailedCalculationPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'previousInsurer': {
        this.router.navigate([PathNames.previousInsurerPage.url, this.currentCarSaleId()]);
        break;
      }
      case 'paymenent': {
        this.router.navigate([PathNames.paymentDetailsPage.url, this.currentCarSaleId()]);
        break;
      }
      default: {
        break;
      }
    }
  }

}
