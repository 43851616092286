import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PathParams } from 'src/app/app-routing-pathnames';
import { AbstractPageComponent } from '../abstract-page/abstract-page.component';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent extends AbstractPageComponent implements OnInit {

  submitted = false;

  loginForm: FormGroup;
  carSaleId: string;
  showLoginErrorMessage: boolean;
  showAuthErrorMessage: boolean;

  constructor(private route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.carSaleCacheService.clear();
    super.ngOnInit();
    this.buildForm();

    // get carsale id from request
    this.carSaleId = this.route.snapshot.paramMap.get(PathParams.carSaleId);

    if (this.authService.isAuthenticated(this.carSaleId)) {
      this.redirectToLastPageIfPossible();
    } else {
      this.authService.login(this.carSaleId).subscribe(
        data => {
          this.showLoginErrorMessage = !data;
        },
        err => {
          this.showLoginErrorMessage = !err;
        }
      );
    }
  }

  private buildForm() {
    this.loginForm = this.formBuilder.group({
      smsToken: ['', [Validators.required, Validators.pattern('[0-9]{6}')]]
    });
  }

  private onSubmit(smsToken: string) {
    this.submitted = true;

    this.authService.authenticate(smsToken).subscribe(
      data => {
        this.showAuthErrorMessage = !data;

        if (data) {
          this.redirectToLastPageIfPossible();
        } else {
          this.submitted = false;
        }
      },
      err => {
        this.showAuthErrorMessage = !err;
        this.submitted = false;
      }
    );
  }

  private redirectToLastPageIfPossible() {
    if (this.authService.isAuthenticated(this.carSaleId)) {
      this.authService.redirectToResumePage(true);
    }
  }
}
