import { ApplicationState, AuthenticationRequest, Consent, LoginService, LogoutService } from '@coc-kfz-digital/oma-rest-api-client';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PathNames } from 'src/app/app-routing-pathnames';
import { environment } from 'src/environments/environment';
import { CarSaleCacheService } from './carSaleCache.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private carSaleId: string;
  private environment = environment;

  constructor(
    private loginService: LoginService,
    private logoutService: LogoutService,
    private router: Router,
    private carSaleCacheService: CarSaleCacheService) {
  }

  public isAuthenticated(carSaleId: string): boolean {
    if (moment().isAfter(this.getExpiration()) || carSaleId !== this.currentCarSaleId()) {
      this.updateAuthentication();
    }

    return localStorage.getItem('car_sale_id') != null &&
      moment().isBefore(this.getExpiration());
  }

  /**
   * Returns the current carsale id to use in the whole application
   */
  public currentCarSaleId(): string {
    return localStorage.getItem('car_sale_id');
  }

  /**
   * Returns the current application state
   */
  public currentApplicationState(): ApplicationState {
    return JSON.parse(localStorage.getItem('application_state'));
  }

  /**
   * Do a login call to the backend for the given car sale id.
   *
   * @param carSaleId the carsale
   */
  public login(carSaleId: string): Observable<any> {
    this.carSaleId = carSaleId;

    const loginObservable = this.loginService.login(this.carSaleId, 'response').pipe(switchMap(data => {
      return of(data.ok);
    }));

    return loginObservable;
  }

  /**
   * Do an authentication call to the backend for the current car sale id.
   *
   * @param password the password used for authentication
   */
  public authenticate(password: string): Observable<any> {
    const authRequest: AuthenticationRequest = { token: password };

    const authObservable = this.loginService.authenticate(this.carSaleId, authRequest, 'response').pipe(
      switchMap(data => {
        if (data.ok && data.body) {
          this.updateAuthentication(true, data.body.applicationState);
        }
        return of(data.ok);
      }),
      catchError(err => {
        return of(err.ok);
      })
    );

    return authObservable;
  }

  /**
   * Do a logout call to the backend to trigger a cookie invalidation from backend side.
   * Will remove all information stored in the browser as well.
   */
  public logout(): Observable<any> {
    return this.logoutService.logout().pipe(switchMap(value => {
      this.doLogout();
      return of(value);
    }));
  }

  /**
   * Do a logout call to the backend to trigger a cookie invalidation from backend side, without redirect.
   * Will remove all information stored in the browser as well.
   */
  public deleteLoginToken() {
    return this.logoutService.logout().pipe(switchMap(value => {
      this.updateAuthentication();
      return of(value);
    }));
  }

  /**
   * Internal logout method to remove authentication information from browser.
   * External calls should be made by AuthInterceptor only!!!
   */
  doLogout() {
    const redirectCarSaleId = this.currentCarSaleId();
    this.updateAuthentication();
    this.router.navigate([PathNames.loginPage.url, redirectCarSaleId]);
  }

  private getExpiration() {
    const expiration = localStorage.getItem('expires_at');
    const expiresAt = JSON.parse(expiration);
    return moment(expiresAt);
  }

  private updateAuthentication(authentication?: boolean, applicationState?: ApplicationState) {
    if (authentication) {
      const expiresAt = moment().add(environment.session_expiration, 'second');

      localStorage.setItem('car_sale_id', this.carSaleId);
      localStorage.setItem('expires_at', JSON.stringify(expiresAt.valueOf()));
      this.updateApplicationState(applicationState);
      this.carSaleCacheService.clear();
    } else {
      localStorage.clear();
    }
  }

  /**
   * Updates the global application state in the local storage
   *
   * @param applicationState the state
   */
  public updateApplicationState(applicationState: ApplicationState) {

    if (environment.application_state_debug) {
      this.logApplicationState(applicationState);
    }

    localStorage.setItem('application_state', JSON.stringify(applicationState));
  }

  /**
   * Helper method to log the application state
   *
   * @param applicationState the state
   */
  private logApplicationState(applicationState: ApplicationState) {
    console.log('--- updateApplicationState ---');
    if (applicationState && applicationState.lastPage) {
      console.log('lastPage: ' + applicationState.lastPage);
    }

    if (applicationState && applicationState.consents) {
      console.log('consents: ');
      applicationState.consents.forEach(consent =>
        console.log('consent: [name= '
          + consent.name + ', version: '
          + consent.version + ', accept: '
          + consent.accept + '].'));
    }
    console.log('--- updateApplicationState ---');
  }

  /**
   * Redirects to the page in the process, where the last session could be reasonably continued/resumed
   *
   * @param checkDataPrivacySettings set to true, if privacy consents should be considered while evaluation the correct page
   */
  public redirectToResumePage(checkDataPrivacySettings: boolean) {
    // check for unchecked consents and redirect to welcome page if needed
    if (checkDataPrivacySettings && this.showPrivacyConsent()) {
      this.router.navigate([PathNames.welcomePage.url, this.currentCarSaleId()]);
      return;
    }

    // resume with last application state
    const applicationState = this.currentApplicationState();
    const lastPage = applicationState ? applicationState.lastPage : null;
    switch (lastPage) {
      case 'successful_proposal':
        this.router.navigate([PathNames.proposalResultPage.url, this.currentCarSaleId()]);
        break;
      case 'quick_calculation':
      default:
        this.router.navigate([PathNames.quickCalculationPage.url, this.currentCarSaleId()]);
        break;
    }
  }

  /**
   * Returns whether to privacy consents needs to be shown
   */
  private showPrivacyConsent(): boolean {
    return !this.consentExists(Consent.NameEnum.DataProtectionGeneralTerms, environment.consent_versions.data_protection_general_terms);
  }

  /**
   * Returns whether the contact consents can be shown
   */
  public showConsultationConsents(): boolean {
    return !(this.consentExists(Consent.NameEnum.ConsultationViaEmail) || this.consentExists(Consent.NameEnum.ConsultationViaTelephone));
  }

  /**
   * Checks whether the given consent and version exists
   *
   * @param name the name of the consent
   * @param version the version of the consent
   */
  public consentExists(name: Consent.NameEnum, version?: string): boolean {
    const applicationState = this.currentApplicationState();

    if (!applicationState || !applicationState.consents) {
      return true;
    }
    // find desired consent in list of consents
    const existingConsent = applicationState.consents.find(consent => {
      return consent.name === name &&
        (consent.version === version || version === undefined || version === null) &&
        consent.accept === true;
    });

    return existingConsent !== undefined;

  }
}
