import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PathParams } from 'src/app/app-routing-pathnames';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  logoutEnabled = false;

  private logoutSubscription: Subscription;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const carSaleId = this.router.routerState.snapshot.root.firstChild.paramMap.get(PathParams.carSaleId);
        if (this.authService.currentCarSaleId() !== null && carSaleId === this.authService.currentCarSaleId()) {
          this.logoutEnabled = true;
        } else {
          this.logoutEnabled = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
  }

  private onLogout(): void {
    if (this.logoutSubscription) {
      this.logoutSubscription.unsubscribe();
    }
    this.logoutSubscription = this.authService.logout().subscribe();
  }


}
