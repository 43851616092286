import { CarSaleService, Consent, InlineResponse200 } from '@coc-kfz-digital/oma-rest-api-client';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of, Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { safeUnsubscribe } from 'src/app/shared/utils';
import { environment } from 'src/environments/environment';
import { AbstractPageComponent } from '../abstract-page/abstract-page.component';

@Component({
  selector: 'app-welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent extends AbstractPageComponent implements OnInit, OnDestroy {

  submitted = false;

  carSaleSubscription: Subscription;
  consentSubscription: Subscription;
  isConsultationConfirmedSubscription: Subscription;
  isConsultationByEmailSubscription: Subscription;
  isConsultationByTelephoneSubscription: Subscription;

  showConsultationCheckboxes: boolean;

  welcomeForm: FormGroup;

  constructor(private route: ActivatedRoute, private carSaleService: CarSaleService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.showConsultationCheckboxes = this.authService.showConsultationConsents();
    this.buildForm();
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    safeUnsubscribe(this.consentSubscription);
    safeUnsubscribe(this.isConsultationConfirmedSubscription);
    safeUnsubscribe(this.isConsultationByEmailSubscription);
    safeUnsubscribe(this.isConsultationByTelephoneSubscription);
  }

  private buildForm() {
    // build form
    this.welcomeForm = this.formBuilder.group({
      isDataAgreementConfirmed: [false, Validators.requiredTrue],
    });

    if (this.showConsultationCheckboxes === true) {
      this.welcomeForm.addControl('isConsultationConfirmed', this.formBuilder.control(false));
      this.welcomeForm.addControl('isConsultationByEmail', this.formBuilder.control(false));
      this.welcomeForm.addControl('isConsultationByTelephone', this.formBuilder.control(false));

      this.registerConsultationChangeListener();
      // trigger change listener for field to disable dependent controls
      this.welcomeForm.controls.isConsultationConfirmed.setValue(false);
    }
  }

  private registerConsultationChangeListener() {
    // change listener
    this.isConsultationConfirmedSubscription = this.welcomeForm.controls.isConsultationConfirmed.valueChanges.subscribe(value => {
      this.onIsConsultationConfirmedValueChange(value);
    });
    this.isConsultationByEmailSubscription = this.welcomeForm.controls.isConsultationByEmail.valueChanges.subscribe(_ => {
      this.validateConsulationCheckboxesUnchecked();
    });
    this.isConsultationByTelephoneSubscription = this.welcomeForm.controls.isConsultationByTelephone.valueChanges.subscribe(_ => {
      this.validateConsulationCheckboxesUnchecked();
    });
  }

  private onIsConsultationConfirmedValueChange(value: boolean) {
    const email = this.welcomeForm.controls.isConsultationByEmail;
    const phone = this.welcomeForm.controls.isConsultationByTelephone;

    if (value === true) {
      email.setValue(true);
      phone.setValue(true);
      email.enable();
      phone.enable();
    } else {
      if (email.value !== false) {
        email.setValue(false);
      }
      if (phone.value !== false) {
        phone.setValue(false);
      }
      email.disable();
      phone.disable();
    }
  }

  private validateConsulationCheckboxesUnchecked() {
    const bothFalse = this.welcomeForm.controls.isConsultationByEmail.value === false &&
      this.welcomeForm.controls.isConsultationByTelephone.value === false;

    if (bothFalse && this.welcomeForm.controls.isConsultationConfirmed.value !== false) {
      this.welcomeForm.controls.isConsultationConfirmed.setValue(false);
    }
  }

  onSubmit() {
    this.submitted = true;

    const mailChecked = this.showConsultationCheckboxes && this.welcomeForm.controls.isConsultationByEmail.value === true;
    const phoneChecked = this.showConsultationCheckboxes && this.welcomeForm.controls.isConsultationByTelephone.value === true;

    this.consentSubscription = this.getJoinedConsentObservable(mailChecked, phoneChecked)
      .subscribe({
        next: (response) => this.handleConsentResponse(response),
        error: () => this.submitted = false,
      });
  }

  private handleConsentResponse(response: InlineResponse200) {
    safeUnsubscribe(this.consentSubscription);
    if (response.success === true) {
      this.carSaleCacheService.clear();
      this.authService.redirectToResumePage(false);
    }
  }

  /**
   * creating an observable for each consent object and joining them via forkJoin.
   * the resulting observable response gets transformed via the switchMap operator
   * in order to fit as a parameter into the handleConsentResponse method.
   */
  private getJoinedConsentObservable(isConsultationViaEmail: boolean, isConsultationViaTelephone: boolean) {

    const dataProtectionConsent: Consent = {
      name: Consent.NameEnum.DataProtectionGeneralTerms,
      version: environment.consent_versions.data_protection_general_terms,
      accept: true,
    };

    const consultationViaEmail: Consent = {
      name: Consent.NameEnum.ConsultationViaEmail,
      version: environment.consent_versions.consultation_via_email,
      accept: true,
    };

    const consultationViaTelephone: Consent = {
      name: Consent.NameEnum.ConsultationViaTelephone,
      version: environment.consent_versions.consultation_via_telephone,
      accept: true,
    };

    const consentObservables = [this.carSaleService.createCustomerConsent(dataProtectionConsent)];

    if (isConsultationViaEmail) {
      consentObservables.push(this.carSaleService.createCustomerConsent(consultationViaEmail));
    }
    if (isConsultationViaTelephone) {
      consentObservables.push(this.carSaleService.createCustomerConsent(consultationViaTelephone));
    }

    return forkJoin(consentObservables).pipe(switchMap(responses => {
      let success = true;

      responses.forEach(response => {
        success = success && response.success === true;
      });

      const combinedConsent: InlineResponse200 = { success };
      return of(combinedConsent);
    }));
  }
}
