import { Component, Input } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

export interface Routing {
  commands: any[];
  extras?: NavigationExtras;
}

@Component({
  selector: 'app-no-result-tile',
  templateUrl: './no-result-tile.component.html',
  styleUrls: ['./no-result-tile.component.scss']
})
export class NoResultTileComponent {

  environment = environment;

  @Input() lastPageRouting: Routing;

  constructor(public router: Router) {
  }

  private onSubmit() {
    this.redirectToLastPageIfPossible();
  }

  private redirectToLastPageIfPossible() {
    if (this.lastPageRouting && this.lastPageRouting.commands) {
      this.router.navigate(this.lastPageRouting.commands, this.lastPageRouting.extras);
    }
  }

}
