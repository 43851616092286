import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-general-error-page',
  templateUrl: './general-error-page.component.html',
  styleUrls: ['./general-error-page.component.scss']
})
export class GeneralErrorPageComponent {

  environment = environment;

  returnToLastPage() {
    window.history.back();
  }
}
