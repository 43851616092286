import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { PathNames } from 'src/app/app-routing-pathnames';
import { AuthService } from '../services/auth.service';

/**
 * https://scotch.io/@vigneshsithirai/angular-6-7-http-client-interceptor-with-error-handling
 * https://blog.angular-university.io/rxjs-error-handling/
 */
@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private injector: Injector, private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let observable: Observable<HttpEvent<any>>;
        const carSaleId = localStorage.getItem('car_sale_id');

        if (carSaleId != null && moment().isBefore(this.getExpiration())) {
            const cloned = req.clone({
                headers: req.headers
                    .set('Car-Sale-Id', carSaleId)
            });
            observable = next.handle(cloned);
        } else {
            observable = next.handle(req);
        }

        return observable.pipe(catchError(this.handleErrors(req, next)));
    }

    private getExpiration() {
        const expiration = localStorage.getItem('expires_at');
        const expiresAt = JSON.parse(expiration);
        return moment(expiresAt);
    }

    private handleErrors(req: HttpRequest<any>, next: HttpHandler) {
        return (response: any) => {
            console.debug('Error while request execution status: ' + response.status + ' statusText: ' + response.statusText);
            switch (response.status) {
                case 401:   // 401 Unauthorized
                case 403:   // 403 Forbidden
                    console.debug('Handling status code ' + response.status + ' => perform client side logoout');
                    const authService: AuthService = this.injector.get(AuthService);
                    authService.doLogout();
                    return throwError(response);
                case 400:
                case 405:
                case 500:
                case 501:
                case 502:
                case 503:
                case 504:
                case 0:
                    console.debug('Handling status code ' + response.status + ' => redirect to error page');
                    this.router.navigate([PathNames.errorPage.url]);
                    return throwError(response);
                    break;
                default:
                    return throwError(response);
            }
        };
    }
}
